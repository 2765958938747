import React from 'react'
import { MdFileDownload } from "react-icons/md";

const DownloadBtn = ({title,btnClass,iconEnable,iconClass}) => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = "/apks/game.apk";
  
    link.download = 'Farmasea Rummy.apk';
 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <button className={btnClass} onClick={handleDownload}>
       {iconEnable && <MdFileDownload  className={iconClass}/>}
        {title}</button>
  )
}

export default DownloadBtn