import React from 'react'
import DownloadBtn from './DownloadBtn';
import logo from '../../assets/images/rummy.png';


const CommonApk = () => {
  return (
    <div>
    <div className='d-flex flex-row'>
<div style={{width:"70px",height:"70px"}}>
<img src={logo}  width={80} height={80}alt=""/>

</div>
<div style={{paddingLeft:"20px"}}>
         
         <h4>Farmasea Rummy</h4>
            <p>Install and Play Farmasea Rummy to win real rupees in cash!</p>
     </div>
     </div>
     <DownloadBtn  title ="Download Apk" btnClass = "btn_download" iconEnable={false}/>
</div>
  )
}

export default CommonApk