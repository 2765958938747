import React from 'react'
import CommonTitle from './CommonTitle';
import CommonApk from './CommonApk';

const RefundPolicy = () => {
  return (
    <div className='container contact'>
    <CommonTitle titleClass="text" title="Refund Policy" data ="Contact Farmasea Rummy Team for more help"/>
     
      <div className='container d-flex flex-row justify-content-start' >
          <CommonApk/>
          
         

          <div className="container d-flex flex-column" style={{paddingLeft:"15px",minHeight:"360px"}}>
                    <h3>Refund Policy</h3>
                    <p>CANCELLATION POLICY</p>

<p>Once chips have been granted, we do not offer CANCELLATION. If your chips have not been granted for whatever reason, please contact the customer support and your chips will be granted immediately. In case we are unable to grant you chips, you can cancel your payment by contacting our customer support and your refund will be processed from our end within 7 working days.</p>

<p>REFUND POLICY</p>

<p>Once chips have been granted, we do not offer refunds. If your chips have not been granted for whatever reason, please contact the customer support and your chips will be granted immediately. In case we are unable to grant you chips, your refund will be processed from our end within 7 working days. If you are refunded even the chips have been granted, your account will be frozen after paying all your balance.</p>

<ul>
	<li>2024&nbsp;FARMASEA ESSENTIALS PRIVATE LIMITED</li>
</ul>
                </div>
          
      </div>
     
  </div>
  )
}

export default RefundPolicy