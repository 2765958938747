import React from 'react'
import CommonTitle from './CommonTitle';
import CommonApk from './CommonApk';

const Contact = () => {
  return (
    <div className='container contact'>
      <CommonTitle titleClass="text" title="Contact Us" data ="Contact Farmasea Rummy Team for more help"/>
       
        <div className='container d-flex flex-row justify-content-start' >
            <CommonApk/>
            
            <div className="container d-flex flex-column" style={{minHeight: "360px",paddingLeft:"15px"}}>
                 <h3>Contact Us</h3>
                 <h5>farmasearummy.in</h5>
               <p>Mangaluru Collectorate Sub Post Office,<br/>
               PRAGATHI HOUSE, B R KARKERA PANDESHWARA,<br/>
               Bunder, Mangaluru, Dakshina Kannada, Karnataka, 575001<br/>
               +91 974 140 7141
              </p>
             </div>
           
        </div>
       
    </div>
  )
}

export default Contact