import React from 'react'
import DownloadBtn from './DownloadBtn'
import install from "../../assets/images/install-unknown-sources.jpg"
import google from "../../assets/images/google-play-protect.png";

const Dowwnload = () => {
  return (
   <div className='container-download'>
     <div className='banner banner-small'>
        <div className='text'>
        <h1>JOIN Farmasea Rummy <br/> AND RECEIVE A DAILY CASH BONUS.</h1>
        <p>Farmasea Rummy</p>
      <DownloadBtn title ="Click to Restart" btnClass = "download-btn" iconEnable={true} iconClass="icon-download"/>
        </div>
        </div>
        <div className='container'>
          <div className='how-to'>
          <div class="title">How to Install Farmasea Rummy</div>
            <div className='item'>
              <div>1</div>
              <p>Download Sky Rummy on your Android <br/>Device</p>
            </div>
            <div className="item">
                <div>2</div>
                <p>Open the file from the notification area or from your download folder, select Install.</p>
            </div>
            <div className="item">
                <div>3</div>
                <p>You may have to allow Unknown Sources at Settings &gt; Security Screen</p>
            </div>

          </div>
        </div>
        <div className="section_down">
            <div className="big-title">How to install unknown sources APK</div>
           <div className= "container d-flex flex-row justify-content-around">
         
                <img src={install} alt="Install unknown sources apk"/>
      
       
                <div class="text">
                    <div class="title">Install APK outside Play Store on Android 8 or higher</div>
                    <p>Enabling Sideloading on Android 8 &amp; Higher, unlike enable allowing unknown sources APK
                        installtion. When you try download an APK with your browser on Android 8 or higher, you'll need
                        to give your browser permission to install apps.</p>
               
            </div>
           </div>
            
        </div>

        <div class="section_down">
        <div className= "container d-flex flex-row-reverse justify-content-around">
                <img src={google} alt="google play protect Sky Rummy"/>
           
           
                <div class="text">
                    <div class="title">You're still protected</div>
                    <p>Even when installing Apps from Unknown sources, Google Play Protect will continue scanning Apps
                        on your Mobile, looking for viruses, malware and blocking forbidden Apps. Sky Rummy                        is safe to play.</p>
                </div>
            </div>
           
        </div>
   </div>
  )
}

export default Dowwnload