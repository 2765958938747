import React from 'react'
import DownloadBtn from './DownloadBtn'
import { FaFacebook } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
import { SlSocialVkontakte } from "react-icons/sl";
import BrandLogo from "../../assets/images/rummy.png"
import rummyImg from "../../assets/images/20230907_1142_64f969d576857.png"
import FeatureImg from "../../assets/images/20230907_1142_64f969d577393.png"
import FeatureImg1 from "../../assets/images/20230215_1305_63ec8b4854d88.jpg"
import FeatureImg2 from "../../assets/images/20230215_1309_63ec8c43e339f.jpg"
import FeatureImg3 from "../../assets/images/20230215_1309_63ec8c4eb5934.jpg"


const Home = () => {
  return (
    <div className='container-main'>
        <div className='banner-bg'>
        <div className='text'>
        <h1>JOIN Farmasea Rummy <br/> AND RECEIVE A DAILY CASH BONUS.</h1>
        <p>Farmasea Rummy</p>
      <DownloadBtn title ="Download the App" btnClass = "download-btn" iconEnable={true} iconClass="icon-main"/>
        </div>
        </div>
        <div className="section_1">
            <div className="container d-flex gap-2 justify-content-around">
                    <div className="text1 w-70 ">
                        <h2>Farmasea Rummy <br/> Play Farmasea Rummy for a chance to win real cash prizes in Indian rupees.</h2>
                        <p>Follow <b>Farmasea Rummy</b> on social media</p>
                        <div >
                            <FaFacebook />
                            <AiFillTwitterCircle />
                            <SlSocialVkontakte />
                        </div>
                </div>
                
                    <div className="text1 w-30">
                        <p>
                            Farmasea Rummy is online Rummy portal completely owned and operated by
                            Rewardoo Private  Limited, We providing best-in-class gaming experience to all its users. Our aim
                            is to
                            give every
                            player a premium and hassle-free rummy-playing experience on our android and iOS app. Our
                            games are designed in a manner that anybody can play without facing any difficulties.
                        </p>
                        <p>
                            Farmasea Rummy is on a mission to promote Rummy &amp; Farmasea Rummy as a professional
                            game and
                            create a community of skilled gamers. Rummy &amp; Farmasea Rummy is a game of skill as
                            opposed to games
                            of chance. It is involves skill to win.
                        </p>
                        <p>
                            We at Farmasea Rummy understand the needs of online gaming in India and strive to
                            provide better and faster results through our software. Our software is built on a custom
                            built architecture to be robust in operating Multi-Player Tables and Online Tournaments
                        </p>
                    </div>
                
                
            </div>
        </div >

        <div className='container section_2'>
            <div className='icon'>
                <img src={BrandLogo} width={100} height={100} alt='brand logo'></img>
                <div>Indian Cards Games</div>
                <p className='name'>Farmasea Rummy</p>
               </div>
               <div className="divider">
                <div className="number">1</div>
                
            </div>
            <div className='container d-flex justify-content-around gap-3'>
            <div className="text1" style={{width:"50%"}}>
                    <div className="title">Realtime Gaming (RTG)</div>
                    <p>Play Farmasea Rummy on mobile with all players world around. Share your love for
                        Farmasea Rummy &amp; Free money.
                    </p>
                    <div className="title">Practice for free</div>
                    <p>Practice Farmasea Rummy free online with other rukkie players. Learn Farmasea Rummy                        through hands-on
                        experience. Practice straight away!</p>
                    <div className="title">Win Real Money</div>
                    <p>Compete in your favorite Farmasea Rummy game to win REAL MONEY!</p>
                </div>
                <div style={{width:"50%"}}>
                <img src={rummyImg} style={{width:"100%",height: "350px"}} alt='rummyimg'></img>
                </div>
            </div>
        </div>
        <div className='container section_3'>
               <div className='d-flex flex-row'>
               <div className="divider">
                <div className="number">2</div>
                </div>
                <div className="divider">
                <div className="number">3</div>
                </div>
                </div>
                
                <div className='d-flex flex-row'>
                <div style={{width:"50%",height:"50%"}}>
                <img src={FeatureImg} style={{width:"100%",height: "350px",marginTop:"50px"}} alt="Feature"/>
               
                <div style={{fontSize:"22px",textAlign:"center",padding:"20px"}}>Easy SignUp</div>
                <p>Sign up Farmasea Rummy with Facebook Account and WIN BIG with! Deposit or withdraw money for
                        registered
                        players only.</p>
                
                </div>
                
                <div style={{width:"50%", }}>
                <div style={{border: "1px solid #dcdcdc",borderBottom: "none",borderRight: "none",borderTop:"none",boxSizing:"border-box",position: "relative"}}>
                <img src={FeatureImg1} style={{width:"100%",height: "250px",marginTop:"50px"}} alt="Featured1"/>
                <div style={{fontSize:"22px",textAlign:"center",padding:"20px"}}>Free Chips</div>
                <p style={{padding: "0px 10px 60px 10px"}}>Daily Bonus: Claim Free chips everyday in Farmasea Rummy. Win a daily bonus of up to $30000 in chips!</p>

                <div className="divider">
                <div className="number">4</div>
                </div>

                </div>
                <img src={FeatureImg2} style={{width:"100%",height: "250px",marginTop:"50px"}}alt="Featured2"/>
                <div style={{fontSize:"22px",textAlign:"center",padding:"20px"}}>Win Cash in Game</div>
                <p style={{paddingLeft:"10px"}}>Select your preferred stakes 0.2Rs/0.5Rs and start playing Farmasea Rummy right away.</p>
                <div style={{border: "1px solid #dcdcdc",borderBottom: "none",borderRight: "none",borderTop:"none",boxSizing:"border-box",position: "relative"}}>
                <div className="divider">
                <div className="number">5</div>
                </div>
                <img src={FeatureImg3} style={{width:"100%",height: "250px",marginTop:"50px"}} alt="Featured3"/>
                <div style={{fontSize:"22px",textAlign:"center",padding:"20px"}}>Indian Cards Games</div>
                <p style={{padding: "0px 10px 60px 10px"}}>Play Farmasea Rummy anytime, anywhere. Win a lot of chips in new game modes and Variations. Win the most chips to come out on top!</p>
                </div>
                </div>
               
                </div>
        </div>
        <div className='container d-flex flex-row gap-2 pb-3'>
            <img src={rummyImg} height={200} alt='rummy'/>
            <img src={FeatureImg} height={200} alt='Feature'/>
            <img src={FeatureImg1} height={200} alt='Feature1'/>


        </div>


     

              
   
    </div>   
  )
}

export default Home