import React from 'react'
import {Link} from 'react-router-dom'
 import rummy from "../assets/images/rummy.png"

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // This enables smooth scrolling
    });
  };
  return (
    <footer className='footer'>
        <div className='footer-main'>
        <div className="top d-flex">
            <img src={rummy} alt="logo"width={48}height={48}/>
         <p style={{paddingLeft:"5px"}}>Farmasea Rummy - The Most Popular Indian Cards Games, play Farmasea Rummy online win real rupees
            with Millions of Players
            around the world in real-time!</p>
            
        </div> 
        <div className="footer_item">
            <Link onClick={scrollToTop} to="/">Home</Link>
            <Link onClick={scrollToTop} to="/download">Download</Link>
            <Link onClick={scrollToTop} to="/faq">FAQ</Link>
            <Link onClick={scrollToTop} to="/about-us">About Us</Link>
            <Link onClick={scrollToTop} to="/privacy-policy">Privacy Policy</Link>
            <Link onClick={scrollToTop} to="/terms-conditions">Terms &amp; Conditions</Link>
            <Link onClick={scrollToTop} to="/refund-policy">Refund Policy</Link>
            <Link onClick={scrollToTop} to="/security">Security</Link>
            <Link onClick={scrollToTop} to="/contact-us">Contact us</Link>
        </div> 
        
        <div className='copy'> © 2024 FARMASEA ESSENTIALS PRIVATE LIMITED. All rights reserved</div>  
        </div>
    </footer>
  )
}

export default Footer