import { Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/pages/Home";
import Download from "./components/pages/Download";
import Footer from "./components/Footer";
import Contact from "./components/pages/Contact";
import Security from "./components/pages/Security";
import RefundPolicy from "./components/pages/RefundPolicy";
import TermsConditions from "./components/pages/TermsConditions";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import AboutUs from "./components/pages/AboutUs";
import Faq from "./components/pages/Faq";


function App() {
  return (
    <div  >
   <Header/>
   <Routes>
    <Route path="/" element= {<Home/>}/>
    <Route path = "/download" element={<Download/>}/>
    <Route path="/faq" element={<Faq/>}/>
    <Route path="/about-us" element={<AboutUs/>}/>
    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
    <Route path="/terms-conditions" element={<TermsConditions/>}/>
    <Route path="/refund-policy" element={<RefundPolicy/>}/>
    <Route path="/security" element={<Security/>}/>
    <Route path="/contact-us" element={<Contact/>}></Route>
   </Routes>
  <Footer/>
   </div>
  );
}

export default App;
