import React from 'react'

const CommonTitle = ({titleClass,title,data}) => {
  return (
    <div className={titleClass}>
    <h1>{title}</h1>
    <p>{data}</p>
    </div>
  )
}

export default CommonTitle