import {NavLink } from "react-router-dom";
import BrandLogo from "../assets/images/rummy.png"
const Header = () => {
  return (
    <header className='header d-flex justify-content-between'>
      

      <a  href="/"><img src={BrandLogo}width={100} height={100} alt="logo"/></a>
      <nav>
      <ul  className="d-flex justify-content-center">
                <li>
                    <NavLink to="/">Home</NavLink>
                </li>
                <li>
                <NavLink to="/download">Download</NavLink>
                </li>
                <li>
                <NavLink to="/faq">FAQ</NavLink>
                </li>
                <li>
                <NavLink to="/about-us">About us</NavLink>
                </li>
                <li>
                <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                </li>
                <li>
                <NavLink to="/terms-conditions">Terms &amp; Conditions</NavLink>
                </li>
                <li>
                <NavLink to="/refund-policy">Refund Policy</NavLink>
                </li>
                <li>
                <NavLink to="/security">Security</NavLink>
                </li>
                <li>
                <NavLink to="/contact-us">Contact us</NavLink>
                </li>
            </ul>
      </nav>
      
    </header>
  )
}

export default Header;