import React from 'react'
import CommonTitle from './CommonTitle';
import CommonApk from './CommonApk';
import teen from "../../assets/images/teen-patti-cash-ranking-of-hands.png";

const Faq = () => {
  return (
    <div className='container contact'>
    <CommonTitle titleClass="text" title="FAQ" data ="Frequently Asked Questions"/>
     
      <div className='container d-flex flex-row justify-content-start' >
          <CommonApk/>
          
          
          <div  className="container d-flex flex-column" style={{paddingLeft:"15px"}}>
          
                <div class="title"><b>I can't install Farmasea Rummy App</b></div>
                <p>Make sure "Unknown Sources" is enabled at Settings &gt; Security.<br/>
                    Download the Farmasea Rummy app again from our official website.<br/>
                    Click on the apk file on your notifications area or download folder.</p>
                <div class="title"><b>I can't update Farmasea Rummy</b></div>
                <p>If for any reason, you're unable to update to the latest version of Farmasea Rummy, please
                    uninstall the version you have and then download and install the app again.</p>
                <div class="title"><b>How to play Farmasea Rummy</b></div>
                <p>Farmasea Rummy is typically played by 3 to 6 players uses 52-card pack without jokers. Like
                    other poker and rummy games, Farmasea Rummy starts by putting down a wager. For the most part,
                    there must be a fixed sum set up that players are eager to wager before the cards are dispersed.
                    After a player has put down the wager and the boot sum is gathered from everybody, every player is
                    managed three cards face down. The boot sum is the base measure of money in question which is kept
                    in the pot. The pot sits in the table. <br/><br/>
                    When the player and the sellers are managed three cards, the following move is to make a call or a
                    raise. In the event that you have played the round of Poker previously, you should know about these
                    two terms. To make a call demonstrates that the player will proceed in the game, however won't raise
                    his wager, though, making a raise implies that the player will include money in the pot,
                    consequently gambling to win or lose more than the main wager. <br/><br/>
                    In any case, one should take note of that that wagering on Farmasea Rummy isn't equivalent to
                    poker. In Farmasea Rummy, you are required to have all the wagers in equivalent sums, implying
                    that once a player makes a wager of 2 coins with another player putting 4, the past player should
                    put 4 extra coins rather than only 2 to the prior 2. <br/><br/>
                    As the game keeps on advancing, the money sum begins to develop and is won by the individual who
                    remains in the game until the finish of the hand and has the best hand or the most noteworthy hand.
                    It is settled on the cards positioning from most noteworthy to the least.</p>
                <div class="title"><b>What's the Farmasea Rummy Rules</b></div>
                <p>The object of the game is to have the best three-card hand and to raise the pot before the showdown.
                    Any hand of a higher category beats any hand of a lower category. If two players have the same
                    combination then the pot is split between the two no matter which of the two players asked for
                    showdown. In another variation, where the suits hold ranks, the player with the higher suit wins the
                    pot.</p>
                <div>
                    <img style={{display:"block",margin:"0 auto",width: "320px"}} src={teen} alt="google play protect Farmasea Rummy"/>
                
            </div>
            
                </div>
          
      </div>
     
  </div>
  )
}

export default Faq